import React, { useState, useEffect } from "react";
import Chart from "chart.js";

function ChartComponent() {
  let [activeClass, setClass] = useState(null);
  let [timeFormat, setFormat] = useState("MMM D");

  let [chartData, setChartData] = useState([]);

  function chartapihit() {
    fetch("https://mainnetapi.pandoproject.org/apis/chart").then((resp) => {
      resp.json().then((res) => {
        setChartData(
          res.quote.map((item) => ({ t: new Date(item.timestamp).toISOString().split('T')[0], y: item.price.toFixed(4) }))
        );
      });
    });
  }

  useEffect(() => {
    chartapihit();
  }, []);

  useEffect(() => {
    var ctx = document.getElementById("myChart").getContext("2d");
    var myChart = new Chart(ctx, {
      type: "line",
      data: {
        // labels: ['12Mar', '13Mar', '14Mar', '15Mar', '16Mar', '17Mar'],
        datasets: [
          {
            label: "PTX/USDT Chart",
            data: chartData,
            backgroundColor: [
              "rgba(25, 27, 31, 1)",
            ],
            borderColor: [
              "rgba(253, 147, 255, 1)",
            ],
            borderWidth: 2,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              type: "time",
              time: {
                displayFormats: {
                  quarter: { timeFormat },
                },
              },
            },
          ],
        },
      },
    });
  }, [chartData, timeFormat]);

  return (
      <div className="chart-img">
        <canvas
          id="myChart"
          width="400"
          height="200"
          aria-label="Hello ARIA World"
          role="img"></canvas>
      </div>
  );
}

export default ChartComponent;
